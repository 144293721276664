<template>
  <div>
    <v-container class="mt-n4">
      <v-row class="align-center justify-center">
        <v-btn
          outlined
          text
          @click="setToday"
          class="mr-2"
          v-if="!$vuetify.breakpoint.mobile"
          >Heute</v-btn
        >

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="prev()"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>früher</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="next()"
              v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>später</span>
        </v-tooltip>

        <v-menu
          v-model="dateMenu"
          offset-y
          :nudge-left="40"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined text v-bind="attrs" v-on="on" class="mr-4">
              {{ formatDate(focus, true) }}</v-btn
            >
          </template>

          <v-date-picker
            color="primary"
            v-model="focus"
            locale="de-CH"
            first-day-of-week="1"
            show-week
            :locale-first-day-of-year="4"
            @input="dateMenu = false"
          >
            <v-spacer /><v-btn
              text
              @click="
                setToday();
                dateMenu = false;
              "
              >heute</v-btn
            >
          </v-date-picker>
        </v-menu>

        <v-autocomplete
          ref="roomInput"
          autofocus
          auto-select-first
          menu-props="closeOnContentClick"
          v-model="selectedRooms"
          :items="rooms"
          chips
          deletable-chips
          label="Zimmer"
          item-text="description"
          item-value="id"
          multiple
          single-line
          return-object
          clearable
          :search-input.sync="roomSearchInput"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click:close="data.parent.selectItem(data.item)"
            >
              {{ data.item.code }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.code }} </v-list-item-title>
              <v-list-item-subtitle
                >{{ data.item.description.replace(data.item.code + " - ", "") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-dialog
          width="600"
          v-model="roomDialog"
          :close-on-content-click="false"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-bookmark</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-system-bar window
              >mehrere Räume hinzufügen/entfernen<v-spacer /><v-icon
                @click="roomDialog = false"
                >mdi-close</v-icon
              ></v-system-bar
            >

            <v-card-text>
              <v-row
                v-for="category in roomCategories"
                :key="'roomCategory' + category.id"
                class="align-center mt-2"
              >
                <v-col cols="auto">
                  <v-btn
                    outlined
                    icon
                    @click="setCategory(category)"
                    color="primary"
                    title="Räume ersetzen"
                    ><v-icon>mdi-equal</v-icon></v-btn
                  >
                </v-col>
                <v-col class="text-body-1">
                  {{ category.description }}
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    title="Räume hinzufügen"
                    icon
                    outlined
                    @click="addCategory(category)"
                    color="success"
                    :disabled="allAdded(category)"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                  &nbsp;
                  <v-btn
                    title="Räume entfernen"
                    outlined
                    icon
                    @click="removeCategory(category)"
                    color="warning"
                    :disabled="someMissing(category)"
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="roomDialog = false">
                Schliessen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-alert
      v-if="selectedRooms.length == 0"
      type="info"
      text
      prominent
      border="left"
      ><strong>Bitte mindestens einen Raum wählen!</strong><br />
      Weitere Informationen zur Raumbelegung und -reservation findest du in der
      Hilfe (Fragezeichen oben rechts)!</v-alert
    >
    <v-card :loading="loading" v-if="selectedRooms.length > 0">
      <v-system-bar>{{
        weekView
          ? formatDate(start, true) + " – " + formatDate(end, true)
          : formatDate(focus, true)
      }}</v-system-bar>
      <v-calendar
        locale="de-CH"
        ref="calendar"
        v-model="focus"
        :type="weekView ? 'week' : 'category'"
        category-show-all
        category-hide-dynamic
        :categories="selectedRooms"
        :category-text="getCategoryText"
        :events="eventsFiltered"
        :weekdays="'1,2,3,4,5,6'"
        first-interval="7:00"
        interval-count="12"
        category-days="1"
      >
        <template v-slot:category="{ category }"
          ><div class="text-center">
            {{ category.description }}
          </div>
        </template>

        <template v-slot:event="{ event, eventParsed, day, eventSummary }">
          <span>
            <component
              v-if="
                eventParsed.start.date == day.date &&
                eventParsed.end.date == day.date
              "
              :is="{ render: eventSummary }"
            ></component>
            <template v-else>
              <strong>{{ eventParsed.input.name }},&nbsp;</strong>
              <span v-if="weekView"
                >{{ formatDayOfWeek(event.start) }} {{ event.startTime }} -
                {{ formatDayOfWeek(event.end) }}
                {{ event.endTime }}</span
              >
              <span
                v-if="
                  !weekView &&
                  eventParsed.start.date == day.date &&
                  eventParsed.end.date != day.date
                "
                >ab {{ eventParsed.start.time }} (bis
                {{ formatDate(eventParsed.end.date, true) }},
                {{ event.endTime }})
              </span>
              <span
                v-if="
                  !weekView &&
                  eventParsed.start.date != day.date &&
                  eventParsed.end.date == day.date
                "
                >bis {{ eventParsed.end.time }}
                (ab
                {{ formatDate(eventParsed.start.date, true) }},
                {{ event.startTime }})</span
              >
              <span
                v-if="
                  !weekView &&
                  eventParsed.start.date != day.date &&
                  eventParsed.end.date != day.date
                "
                >{{ formatDate(eventParsed.start.date, true) }} bis
                {{ formatDate(eventParsed.end.date, true) }}
              </span>
            </template>
          </span>
        </template>
      </v-calendar>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  addDays,
  findMonday,
  formatDate,
  formatDayOfWeek,
  today,
} from "common/utils/date.js";
import { personCode } from "common/utils/people.js";
export default defineComponent({
  name: "Reservations",
  data() {
    return {
      focus: "",
      dateMenu: false,
      roomDialog: false,
      loading: false,
      start: "",
      end: "",
      search: "",
      events: [],
      reservations: [],
      rooms: [],
      roomCategories: [],
      selectedRooms: [],
      room: {},
      roomSearchInput: null,
    };
  },
  watch: {
    focus() {
      this.fetchReservations();
      window.localStorage.setItem("roomReservationDate", this.focus);
    },
    selectedRooms() {
      const roomCodes = this.selectedRooms.map((el) => el.code);
      window.localStorage.setItem(
        "roomOccupationRooms",
        JSON.stringify(roomCodes)
      );
      this.$router
        .replace({
          query: { rooms: roomCodes },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      this.fetchReservations();
      this.roomSearchInput = null;
      window.setTimeout(() => this.$refs.roomInput.$refs.input.focus());
    },
  },
  computed: {
    eventsFiltered() {
      return this.events.filter((el) =>
        this.selectedRooms.map((el) => el.description).includes(el.category)
      );
    },
    weekView() {
      return !this.$vuetify.breakpoint.mobile && this.selectedRooms.length <= 1;
    },
  },
  methods: {
    formatDayOfWeek,
    addRooms(rooms) {
      for (const room of rooms) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          this.selectedRooms.push(room);
        }
      }
    },
    setRooms(rooms) {
      this.selectedRooms = [];
      this.addRooms(rooms);
      this.roomDialog = false;
    },
    removeRooms(rooms) {
      for (const room of rooms) {
        const index = this.selectedRooms.findIndex((el) => el.id == room.id);
        if (index >= 0) {
          this.selectedRooms.splice(index, 1);
        }
      }
    },
    addCategory(category) {
      for (const room of category.rooms) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          this.selectedRooms.push(room);
        }
      }
    },
    setCategory(category) {
      this.selectedRooms = [];
      this.addCategory(category);
      this.roomDialog = false;
    },
    allAdded(category) {
      for (const room of category.rooms) {
        if (!this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },
    someMissing(category) {
      for (const room of category.rooms) {
        if (this.selectedRooms.some((el) => el.id == room.id)) {
          return false;
        }
      }
      return true;
    },
    removeCategory(category) {
      for (const room of category.rooms) {
        const index = this.selectedRooms.findIndex((el) => el.id == room.id);
        if (index >= 0) {
          this.selectedRooms.splice(index, 1);
        }
      }
    },
    formatDate,
    getCategoryText(cat) {
      return cat.description;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = today();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async fetchReservations() {
      if (!this.focus) {
        return;
      }
      if (this.weekView) {
        this.start = findMonday(this.focus);
        this.end = addDays(this.start, 5);
      } else {
        this.start = this.focus;
        this.end = this.focus;
      }
      this.loading = true;
      const reservations = await this.apiList({
        resource: "register/reservation",
        query: `startDate=${this.start}&endDate=${this.end}`,
      });
      this.events = [];
      for (const item of reservations) {
        const eventStart = item.startTime
          ? new Date(item.startDate + "T" + item.startTime)
          : new Date(item.startDate);
        const eventEnd = item.endTime
          ? new Date(item.endDate + "T" + item.endTime)
          : new Date(item.endDate);
        const mine = this.$_isPerson(item.reservedFor);
        this.events.push({
          id: item.id,
          name: item.description
            ? item.description + " (" + personCode(item.reservedFor) + ")"
            : personCode(item.reservedFor),
          category: item.room.description,
          start: eventStart,
          end: eventEnd,
          color: mine ? "primary" : "grey",
          startTime: item.startTime,
          endTime: item.endTime,
          timed: true,
        });
      }

      for (const room of this.selectedRooms) {
        const lessons = await this.apiList({
          resource: "register/lesson",
          query: `startDate=${this.start}&endDate=${this.end}&room=${room.id}`,
        });
        for (const item of lessons) {
          const eventStart = new Date(
            item.date + "T" + item.period.startTime // + '+01:00'
          );
          const eventEnd = new Date(
            item.date + "T" + item.period.endTime // + '+01:00'
          );

          this.events.push({
            name: item.course
              ? item.course.code +
                " (" +
                item.course.teachers.map((el) => el.code).join(", ") +
                ")"
              : "kein Kurs",
            category: room.description,
            start: eventStart,
            end: eventEnd,
            timed: true,
            color: "info",
          });
        }
      }
      this.loading = false;
    },
    async fetchData() {
      this.loading = true;
      this.rooms = await this.apiList({
        resource: "common/room",
      });
      this.roomCategories = await this.apiList({
        resource: "common/roomcategory",
      });
      this.loading = false;
    },
  },
  async created() {
    await this.fetchData();
    if (this.$route.query.rooms) {
      this.selectedRooms = this.rooms.filter((el) =>
        this.$route.query.rooms.includes(el.code)
      );
    } else if (window.localStorage.getItem("roomOccupationRooms")) {
      const roomCodes = JSON.parse(
        window.localStorage.getItem("roomOccupationRooms")
      );
      this.selectedRooms = this.rooms.filter((el) =>
        roomCodes.includes(el.code)
      );
    }
  },
  mounted() {
    if (window.localStorage.getItem("roomReservationDate")) {
      this.focus = window.localStorage.getItem("roomReservationDate");
    } else {
      this.setToday();
    }
  },
});
</script>
